<script>

import helpers from '../../helpers'

import {
  liveshoppingAnalyticsMethods,

} from "@/state/helpers";

	export default {
		data() {
			return {
				total_sold : '-',
				avg_sold : '-',
				earningsByDay: [
					{
						name: this.$t('kpi.sales_title'),
						data: [],
					},
				],
				chartOptions: {
					chart: {
						height: 320,
						type: "line",
						toolbar: "false",
						dropShadow: {
							enabled: true,
							color: "#000",
							top: 18,
							left: 7,
							blur: 8,
							opacity: 0.2,
						},
					},
					dataLabels: {
						enabled: false,
					},
					colors: ["#556ee6"],
					stroke: {
						curve: "smooth",
						width: 3,
					},
					yaxis:{
            labels: {
              show:true
            }
          },
					/*xaxis: {
						type: 'datetime',
						labels: {
							datetimeFormatter: {
								year: 'yyyy',
								month: 'MMM \'yy',
								day: 'dd MMM',
								hour: 'HH:mm'
							}
						}
					}*/

			},
		};
	},
	watch: {
		filter() {
			if (this.filter) {
				this.applyFilter(this.filter);
			}
		},total_events(){
			if (this.total_events) {
				this.avg_sold = !isNaN(this.total_sold) && !isNaN(this.total_events) && parseFloat(this.total_events)> 0 ? `${ Math.round(parseFloat(this.total_sold) / parseFloat(this.total_events))}` : '-';
			}
		}	
	},
	mounted(){
		const self = this;

		this.chartOptions.xaxis = {
        labels: {
          formatter: function (valor) {
            return self.parseDateWithLocale(new Date(valor), { month: 'short', day: 'numeric', timeZone: 'UTC'});
          }
        }
      }

			this.chartOptions.yaxis = {
        labels: {
          formatter: function (valor) {
            return `$${self.parseMoneyWithLocale(valor)}`;
          }
        }
      }
      this.$refs.earningsChart.updateOptions(this.chartOptions);
	},
	methods: {
		...liveshoppingAnalyticsMethods,
		...helpers,
		
		applyFilter(){
			this.getTotalAmountSold(this.filter).then((res) => {
				this.total_sold = res && res.data && res.data.length > 0 && res.data[0].total_sold ? res.data[0].total_sold : 0;
				this.avg_sold = !isNaN(this.total_sold) && !isNaN(this.total_events) && parseFloat(this.total_events)> 0 ? `${ Math.round(parseFloat(this.total_sold) / parseFloat(this.total_events))}` : '-';
			}).catch(()=>{
				this.total_sold = '-';
				this.avg_sold = '-';
			});

			this.getTotalAmountSoldByDay(this.filter).then((res) => {
				
				if(res.data){
					this.earningsByDay[0].data = Array.from(res.data, x => [ new Date(x.day).getTime(), x.total_cart]) 
					this.earningsByDay[0].data.sort((a, b) => a[0] - b[0]);

					this.$refs.earningsChart.updateSeries(this.earningsByDay,true);
				}
				
			}).catch((err)=>{
				// eslint-disable-next-line no-console
				console.log(err);
			});
		},
	},
	props: {
		filter:{
			type: String,
		},

		total_events:{
			type: Number,
		},
	},
}	
</script>

	<template>
	<div class="card">
		<div class="card-body">
			<div class="clearfix">
				<h4 class="card-title mb-4">{{$t('kpi.sales_title')}}</h4>
			</div>

			<div class="row">
				<div class="col-lg-4">
					<div class="text-muted">
						<div class="mb-4">
							<h4 v-b-popover.hover.bottom="`$${parseMoneyWithLocale(total_sold)}`">{{ `$${parseMoneyReducedWithLocale(total_sold)}` }}</h4>
							<span class="badge badge-soft-success font-size-12 me-1" v-if="this.avg_sold!= '-'" v-b-popover.hover.bottom="`$${parseMoneyWithLocale(avg_sold)}`">  {{ `$${parseMoneyReducedWithLocale(avg_sold)}`}}</span>
								{{$t('kpi.sales_by_event')}}
						</div>
					</div>
				</div>
				<div class="col-lg-8">
					<div id="line-chart"></div>
						<apexchart
							ref="earningsChart"
							class="apex-charts"
							dir="ltr"
							height="300"
							:series="earningsByDay"
							:options="chartOptions">
						</apexchart>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import Earnings from "@/components/dashboard/earnings";
import EventsByDay from "@/components/events/events-by-day";

import {
  liveshoppingAnalyticsMethods,
  campaignMethods,
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    DatePicker,
    Multiselect,
    Earnings,
    EventsByDay
  },
  data() {
    return {
      title: this.$t('dashboard.title'),
      items: [],
      
      showModal: false,
      isLoading: false,
      fullPage: true,
      canCancel: false,
      useSlot: false,
      loader: "spinner",
      color: "#007bff",
      bgColor: "#ffffff",
      height: 128,
      width: 128,
      timeout: 3000, //ms
      
      dateFilter:null,
      todayButton:false,
      yesterdayButton:false,
      lastWeekButton:false,
      lastMonthButton:true,
      campaigns:[],
      selectedCampaign:null,
      totalEvents : 0,
      completedEvents : 0,
      totalMinutes : 0,
      avgMinutes:0,
      lastSyncDate: '-',
      query : ''
    };
  },
  mounted() {
    this.loadCampaigns();
    this.lastMonthButton = true;
    this.applyFilter();

  },
  methods:{
    ...liveshoppingAnalyticsMethods,
    ...campaignMethods,

    formatDate(d) {
      
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;
      
      return [year, month, day].join('-');
    },

    loadCampaigns(){
      let loader = this.$loading.show();

      const params={
        q: `limit=200`,
      }

      params.q+= `&where[project]=${localStorage.getItem('current_project')}` 

      this.getCampaigns(params).then((campaigns)=>{
        if(campaigns&& campaigns.data){
          this.campaigns= campaigns.data;
      }
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("campaigns.get_campaigns_error"),title:  this.$t("campaigns.title") });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    },

    onCampaignSelected(){
      this.applyFilter();
    },

    updateDateFilter() {
      this.selectButtonFilter(null);
      this.applyFilter();
    },

    selectButtonFilter(selected){
      this.todayButton = selected == 'today';
      this.yesterdayButton = selected == 'yesterday';
      this.lastWeekButton = selected == 'last_week';
      this.lastMonthButton = selected == 'last_month';

      if(selected)
        this.dateFilter = null;

      this.applyFilter();
    },
    applyFilter() {
      let today = new Date();
      let query =''
      
      if(this.todayButton){
        let tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);
        query = `dateFrom=${this.formatDate(today)}&dateTo=${this.formatDate(tomorrow)}`;  
      }
      else if(this.yesterdayButton){
        let yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        query = `dateFrom=${this.formatDate(yesterday)}&dateTo=${this.formatDate(today)}`;  
      }else if(this.lastWeekButton){
        var curr = new Date; // get current date
        var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
        var last = first + 6; // last day is the first day + 6

        var firstday = new Date(curr.setDate(first));
        var lastday = new Date(curr.setDate(last));
        query = `dateFrom=${this.formatDate(firstday)}&dateTo=${this.formatDate(lastday)}`;  
      }else if(this.lastMonthButton) {
        const firstday = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastday = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        query = `dateFrom=${this.formatDate(firstday)}&dateTo=${this.formatDate(lastday)}`;  
      }else if(this.dateFilter) {
        query = `dateFrom=${this.formatDate(this.dateFilter[0])}&dateTo=${this.formatDate(this.dateFilter[1])}`;
      }

      if(this.selectedCampaign){
        query+=`&campaign=${this.selectedCampaign._id}`
      }

      this.query = query;

      this.getEventsGeneralStats(query).then((res)=>{
        if(res && res.data){
          this.totalEvents = res.data.filter(a=> a.label == "total_events").length !=0 ? res.data.filter(a=> a.label == "total_events")[0].count : 0;
          this.completedEvents = res.data.filter(a=> a.label == "total_events_closed").length !=0 ? res.data.filter(a=> a.label == "total_events_closed")[0].count : 0;
          this.totalMinutes = res.data.filter(a=> a.label == "total_minutes").length !=0 ? res.data.filter(a=> a.label == "total_minutes")[0].count : 0;
          this.avgMinutes = res.data.filter(a=> a.label == "avg_minutes").length !=0 ? Math.round(parseFloat(res.data.filter(a=> a.label == "avg_minutes")[0].count)) : 0;
        }
      }).catch((ex)=>{
        // eslint-disable-next-line no-console
        console.log(ex);
      })

      this.getEventsGeneralStatsLastSyncDate(query).then((res)=>{
        if(res && res.data && res.data.last_replication_date){
          this.lastSyncDate = new Date(res.data.last_replication_date).toLocaleDateString() + ' ' + new Date(res.data.last_replication_date).toLocaleTimeString([],{hour: '2-digit', minute:'2-digit'});
        }
      });
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl col-sm-8">
                  <div class="form-group mb-0">
                      <label>{{$t('dashboard.filter_date')}}:</label>
                      <div class="d-flex">
                        <date-picker @input="updateDateFilter" v-model="dateFilter" range append-to-body style="padding-right: 10px"></date-picker>
                        <div class="btn-group btn-group-sm pl-2 w-70" role="group" style="width: 70%">
                          <button ref="today" type="button" :class="todayButton ? 'btn btn-primary' :'btn btn-outline-primary'" @click="selectButtonFilter('today')">{{$t('dashboard.filter_today')}}</button>
                          <button ref="yesterday" type="button" :class="yesterdayButton ? 'btn btn-primary' :'btn btn-outline-primary'" @click="selectButtonFilter('yesterday')">{{$t('dashboard.filter_yesterday')}}</button>
                          <button ref="last_week" type="button" :class="lastWeekButton ? 'btn btn-primary' :'btn btn-outline-primary'" @click="selectButtonFilter('last_week')">{{$t('dashboard.filter_this_week')}}</button>
                          <button ref="last_month" type="button" :class="lastMonthButton ? 'btn btn-primary' :'btn btn-outline-primary'" @click="selectButtonFilter('last_month')">{{$t('dashboard.filter_this_month')}}</button>
                        </div>
                      </div>
                  </div>
              </div>
              <div class="col-xl-4 col-sm-4">
                <div class="form-group mb-0">
                  <label>{{$t('dashboard.filter_campaign')}}:</label>
                  <multiselect id="campaign"
                    v-model="selectedCampaign"
                    :options="campaigns"
                    :placeholder="$t('dashboard.campaign_placeholder')"
                    :selectLabel="$t('common.select')"
                    :deselectLabel="$t('common.deselect')"
                    track-by="name" 
                    label="name"
                    :showNoResults="false"
                    :multiple="false"
                    @input="onCampaignSelected">
                  </multiselect>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <p align='right'> {{`${$t('dashboard.last_sync_date')} ${lastSyncDate}` }}</p>
      <!-- end col -->
      
      <div class="col-xl-12">
        <Earnings :filter="query" :total_events = "totalEvents" />
      </div>
      
      <div class="col-xl-12">
        <div class="row">
          <div class="col-sm-3">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs me-3">
                    <span
                      class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-18"
                    >
                      <i class="bx bx-video"></i>
                    </span>
                  </div>
                  <h5 class="font-size-14 mb-0">{{$t('dashboard.total_events')}}</h5>
                </div>
                <div class="text-muted mt-4">
                  <h4>
                    {{totalEvents}}
                    <!--<i class="mdi mdi-chevron-up ms-1 text-success"></i>-->
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs me-3">
                    <span
                      class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-18"
                    >
                      <i class="bx bx-check-circle"></i>
                    </span>
                  </div>
                  <h5 class="font-size-14 mb-0">{{$t('dashboard.completed_events')}}</h5>
                </div>
                <div class="text-muted mt-4">
                  <h4>
                    {{completedEvents}}
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs me-3">
                    <span
                      class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-18"
                    >
                      <i class="bx bx-hourglass"></i>
                    </span>
                  </div>
                  <h5 class="font-size-14 mb-0">{{$t('dashboard.total_minutes')}}</h5>
                </div>
                <div class="text-muted mt-4">
                  <h4>
                    {{totalMinutes}}
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs me-3">
                    <span
                      class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-18"
                    >
                      <i class="bx bx-time"></i>
                    </span>
                  </div>
                  <h5 class="font-size-14 mb-0">{{$t('dashboard.avg_minutes')}}</h5>
                </div>
                <div class="text-muted mt-4">
                  <h4>
                    {{avgMinutes}}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
        <div class="row">
          <EventsByDay :filter="query"></EventsByDay>
        </div>
      </div>
    </div>
    <!-- end row -->
</Layout>
</template>

<script>

import {
    liveshoppingAnalyticsMethods,
} from "@/state/helpers";

export default {
  data() {
    return {
      chartData: {
        series: [
          {
            name: this.$t("dashboard.calls_scheduled"),
            data: [],
          },
          {
            name: this.$t("dashboard.calls_closed"),
            data: [],
          },
          {
            name: this.$t("dashboard.calls_canceled"),
            data: [],
          },
          {
            name: this.$t("dashboard.calls_missed"),
            data: [],
          },
          {
            name: this.$t("dashboard.calls_all"),
            data: [],
          },
        ],
        chartoptions: {
          chart: {
            height: 350,
            type: "area",
            toolbar: {
              show: false,
            },
          },
          colors: ["#f1b44c","#556ee6", "#a1b13c", "#45123f", "#12a5f4"],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
            width: 2,
          },
          
          xaxis: {
            type: 'datetime',
            labels: {
                datetimeFormatter: {
                    year: 'yyyy',
                    month: 'MMM \'yy',
                    day: 'dd MMM',
                    hour: 'HH:mm'
                }
            }
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    return `${value || 0}`;
                }
            },
        },
         
          legend: {
            position: "top",
            horizontalAlign: "right",
          },
          tooltip: {
            x: {
                format: 'dd/MM/yy'
            }            
        }
        },
      },
      selection: "all",
    };
  },
  watch: {
		filter() {
			if (this.filter) {
				this.applyFilter(this.filter);
			}
		}	
	},
  methods: {
    ...liveshoppingAnalyticsMethods,

    applyFilter(){

          this.getEventsByDay(this.filter).then((res)=> {
            if(res.data){
                this.chartData.series[0].data = Array.from(res.data.filter(i=> i.status == 'scheduled'), x => [ new Date(x.day), x.count]); 
                this.chartData.series[1].data = Array.from(res.data.filter(i=> i.status == 'closed'), x => [ new Date(x.day), x.count]); 
                this.chartData.series[2].data = Array.from(res.data.filter(i=> i.status == 'canceled'), x => [ new Date(x.day), x.count]); 
                this.chartData.series[3].data = Array.from(res.data.filter(i=> i.status == 'missed'), x => [ new Date(x.day), x.count]); 
                
                this.$refs.eventsByDayChart.updateSeries(this.chartData.series,true);
            }
          });
			
          this.getAllEventsByDay(this.filter).then((res)=> {
            if(res.data){
                this.chartData.series[4].data = Array.from(res.data.filter(i=> i), x => [new Date(x.day), x.count]); 

                this.$refs.eventsByDayChart.updateSeries(this.chartData.series,true);
            }
          });
    }    
  },
  props: {
		filter:{
			type: String,
		},
  },
};
</script>

<template>
  <div class="card">
  <div class="card-body">
    <div class="d-flex flex-wrap">
      <h5 class="card-title me-2">{{ $t("dashboard.calls_by_day")}}</h5>
    </div>
    <apexchart
      ref="eventsByDayChart"
      class="apex-charts"
      dir="ltr"
      height="350"
      :series="chartData.series"
      :options="chartData.chartoptions"
    ></apexchart>
  </div>
  </div>
</template>
